import React from 'react';
import {Link} from 'react-router-dom';


export default function SideMenu(){
	return(
    <>
			<div className="col-md-3 order-2 mb-5 mb-md-0">
            <div className="border p-4 rounded mb-4">
              <h3 className="mb-3 h6 text-uppercase text-black d-block">Categories</h3>
              <ul className="list-unstyled mb-0">
                <li className="mb-1"><Link to="/Tshirts" className="d-flex"><span>T-Shirts</span> <span classNameName="text-black ml-auto">(5)</span></Link></li>
                <li className="mb-1"><Link to="/Mugs" className="d-flex"><span>Tea Cups</span> <span classNameName="text-black ml-auto">(11)</span></Link></li>
                <li className="mb-1"><Link to="#" className="d-flex"><span>KeyChain</span> <span classNameName="text-black ml-auto">(1)</span></Link></li>
              <li className="mb-1"><Link to="#" className="d-flex"><span>Caps</span> <span classNameName="text-black ml-auto">(1)</span></Link></li>
              <li className="mb-1"><Link to="/Designs" className="d-flex"><span>Mandala Designs</span> <span classNameName="text-black ml-auto">(20)</span></Link></li>
              </ul>
            </div>
          
           
              </div>
              </>
		)
}


