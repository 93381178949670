import logo from './logo.svg';
import './App.css';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Main from './Main';
import Contact from './Screens/Contact'
import Shop from './Screens/Shop'
import Designs from './Screens/Designs'
import Tshirts from './Screens/Tshirts'
import Mugs from './Screens/Mugs'
import About from './Screens/About'
import Review from './Screens/Review'

function App() {
  return (
    <>
   <BrowserRouter>
   <Routes>
   <Route exact path="/" element={<Main />} />
   //Screens
   <Route exact path="/Contact" element={<Contact />} />
   <Route exact path="/Shop" element={<Shop />} />
   <Route exact path="/Designs" element={<Designs />} />
   <Route exact path="/Tshirts" element={<Tshirts />} />
   <Route exact path="/Mugs" element={<Mugs />} />
   <Route exact path="/About" element={<About />} />
    <Route exact path="/Review" element={<Review />} />
   //Reusable
   </Routes>
   </BrowserRouter>
  </>
  );
}

export default App;
