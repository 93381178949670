import React from 'react';
import Header from '../Header Component/Header';
import Footer from '../Footer Component/Footer';
import {Link} from 'react-router-dom';
import SideMenu from '../Reusable/SideMenu';
import  NavbarMenu  from '../Reusable/NavbarMenu';
import Tshirtlist from '../Reusable/Tshirtlist';


export default function Tshirts() {

	return (
		<>
		<Header />
    <NavbarMenu />
		 <div className="custom-border-bottom py-3">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-0"><Link to="/">Home</Link><span className="mx-2 mb-0">/</span><Link to="/Shop">Shop</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Tshirts</strong></div>
        </div>
      </div>
    </div>
    <div className="site-section">
      <div className="container">

        <div className="row mb-5">
          <div className="col-md-9 order-1">
            <div className="row align">
              <div className="col-md-12 mb-5">
                <div className="float-md-left"><h2 className="text-black h5"><u>Tshirts</u>&nbsp;</h2><h3>(Each of these tshirts are customizable. You can select from mandala designs and we can make tshirts of your favourite mandala design.)</h3></div>
                <div className="d-flex">           
                </div>
              </div>
            </div>

           <Tshirtlist />


            <div className="row">
              <div className="col-md-12 text-center">
                <div className="site-block-27">
                  <ul>
                    <li><Link to="#">&lt;</Link></li>
                    <li className="active"><span>1</span></li>
                    {
                    // <li><Link to="#">2</Link></li>
                    // <li><Link to="#">3</Link></li>
                    // <li><Link to="#">4</Link></li>
                    // <li><Link to="#">5</Link></li>  
                }
                <li><Link to="#">&gt;</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
         <SideMenu />
        </div>
      </div>
      </div>
		<Footer />
		</>
		)
}