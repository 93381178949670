import React from 'react';
import Header from '../Header Component/Header';
import Footer from '../Footer Component/Footer';
import {Link} from 'react-router-dom';
import SideMenu from '../Reusable/SideMenu';
import  NavbarMenu  from '../Reusable/NavbarMenu';

export default function Designs() {
   const designs = [
  {
    name: 'Mandala Design 1',
      src: 'images/m1.jpeg',
      price: '200'
  },
  {
    name: 'Mandala Design 2',
      src: 'images/m3.jpeg',
      price: '200'
  },
   {
    name: 'Mandala Design 3',
      src: 'images/m14.jpg',
      price: '200'
  },
     {
      name: 'Mandala Design 4',
      src: 'images/m6.jpeg',
      price: '200'
  },
    {
      name: 'Mandala Design 5',
      src: 'images/m20.jpg',
      price: '200'
  },
   {
    name: 'Mandala Design 6',
      src: 'images/m8.png',
      price: '200'
  },
   {
    name: 'Mandala Design 7',
      src: 'images/m9.jpeg',
      price: '200'
  },
   {
    name: 'Mandala Design 8',
      src: 'images/m10.jpg',
      price: '200'
  },
   {
    name: 'Mandala Design 9',
      src: 'images/m11.png',
      price: '200'
  },
   {
    name: 'Mandala Design 10',
      src: 'images/m12.png',
      price: '200'
  },
     {
      name: 'Mandala Design 11',
      src: 'images/m13.jpg',
      price: '200'
  },
  {
    name: 'Mandala Design 12',
      src: 'images/m10.jpeg',
      price: '200'
  },
   {
    name: 'Mandala Design 13(Mug Design)',
      src: 'images/m4.jpeg',
      price: '200'
  },
  ]
  const mugdesigns = [
    {
      name: 'Mug Design 1',
      src: 'images/m15.jpg',
      price: '200'
  },
    {
      name: 'Mug Design 2',
      src: 'images/m16.jpg',
      price: '200'
  },
    {
      name: 'Mug Design 3',
      src: 'images/m17.jpg',
      price: '200'
  },
   {
      name: 'Mug Design 4 (Also available in Tshirts)',
      src: 'images/m18.jpg',
      price: '200'
  },
   {
      name: 'Mug Design 5',
      src: 'images/m19.jpg',
      price: '200'
  },
  {
      name: 'Mug Design 6', 
      src: 'images/m7.jpeg',
      price: '200'
  },
  {
      name: 'Mug Design 7', 
      src: 'images/m22.jpg',
      price: '200'
  },
  ]
	return (
		<>
		<Header />
    <NavbarMenu />
		 <div className="custom-border-bottom py-3">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span><Link to="/Shop">Shop</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Mandala Designs</strong></div>
        </div>
      </div>
    </div>
    <div className="site-section">
      <div className="container">

        <div className="row mb-5">
          <div className="col-md-9 order-1">

            <div className="row align">
              <div className="col-md-12 mb-5">
                <div className="float-md-left"><h2 className="text-black h5">Designs</h2></div>
                <div className="d-flex">                     
                </div>
              </div>
            </div>
{
//Common designs
}
<div className="row mb-5">
{
  designs.map((u)=>{
    return(
<div className="col-lg-6 col-md-6 item-entry mb-4">
                <Link to="#" className="product-item md-height bg-gray d-block">
                  <img src={u.src} alt="Image" style={{top:65, borderRadius:'25px'}} />
                </Link>
                <h2 className="item-title"><Link to="#"><b style={{color: 'black'}}>{u.name}</b></Link></h2>
              
                  <div className="star-rating">
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                </div>
              </div>
      )
  })
}
</div>
               

  <div className="row align">
    <div className="col-md-12 mb-5">
        <div className="float-md-left"><h2 className="text-black h5"><u>Mug Designs</u></h2></div>
          <div className="d-flex">
          </div>
        </div>
    </div><br/>
{
//Mug designs
}
 <div className="row mb-5">
{
  mugdesigns.map((u)=>{
    return(
<div className="col-lg-6 col-md-6 item-entry mb-4">
                <Link to="#" className="product-item md-height bg-gray d-block">
                  <img src={u.src} alt="Image" style={{top:65, borderRadius:'25px'}} />
                </Link>
                <h2 className="item-title"><Link to="#"><b style={{color: 'black'}}>{u.name}</b></Link></h2>
                  <div className="star-rating">
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                </div>
              </div>
      )
  })
}
</div>
            
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="site-block-27">
                  <ul>
                    <li><Link to="#">&lt;</Link></li>
                    <li className="active"><span>1</span></li>
                    {
                    // <li><Link to="#">2</Link></li>
                    // <li><Link to="#">3</Link></li>
                    // <li><Link to="#">4</Link></li>
                    // <li><Link to="#">5</Link></li>  
                }
                <li><Link to="#">&gt;</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

       <SideMenu />
        </div>

      </div>
      </div>
   


		<Footer />
		</>
		)
}