import React, { useState } from 'react';
import Header from '../Header Component/Header';
import Footer from '../Footer Component/Footer';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import axios from 'axios';
import  NavbarMenu  from '../Reusable/NavbarMenu';

export default function Contact() {
    const [status, setStatus] = useState("Submit");
    const [value, setValue] = useState()
    const submit = async (e) => {
        e.preventDefault();
        setStatus("Sent");
        const { fname, lname, email, phone, message } = e.target.elements;
        let details = {
            fname: fname.value,
            lname: lname.value,
            email: email.value,
            message: message.value,
            phone: phone.value,
        };
        let response = await fetch("http://localhost:5000/users", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        });
        setStatus("Sent");
        // let result = await response.json();
        // alert(result.status);
        toast('Thank you. Your message is sent.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
      });
      };
    return ( <
        >
        <Header /> 
        <NavbarMenu /><
        div className = "site-section" >
        <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="h3 mb-3 text-danger"><u>Get In Touch</u></h2>
               <h2 className="h3 mb-3 text-black"><u>We accept Payments through : </u></h2>
               <li><img src="images/Paytm.png" alt="Image" style={{widht: '40px', height: '20px'}}/></li><br/>
               <li><b style={{color: 'black', fontSize: '20px'}}>Cash on Delivery.</b></li><br/>
          </div>
          <div className="col-md-7">
            <form onSubmit={submit} data-netlify="true">
              <div className="p-3 p-lg-5 border black">
                <div className="form-group row">
                  <div className="col-md-6">
                    <label for="fname" className="text-black"><b>First Name</b> <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" id="fname" name="fname" required aria-required="true"/>
                  </div>
                  <div className="col-md-6">
                    <label for="lname" className="text-black"><b>Last Name</b> <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" id="lname" name="lname" required aria-required="true" />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-12">
                    <label for="email" className="text-black"><b>Enter your Email </b><span className="text-danger">*</span></label>
                    <input type="email" className="form-control" id="email" name="email" placeholder=""  required aria-required="true"/>
                  </div>
                </div> 
                <div className="form-group row">
                  <div className="col-md-12">
                    <label for="Contact" className="text-black"><b>Enter Your Contact</b> <span className="text-danger">*</span></label>
                    <PhoneInput international defaultCountry="IN" countryCallingCodeEditable={false} value={value}
                     onChange={setValue} placeholder="Enter phone number" className="form-control" id="phone" name="phone" required aria-required="true"/>
                  </div>
                </div>  
                <div className="form-group row">
                  <div className="col-md-12">
                    <label for="message" className="text-black"><b>Message </b><span className="text-danger">*</span></label>
                    <textarea name="message" id="message" cols="30" rows="7" className="form-control" required aria-required="true"></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <input type="submit" className="btn btn-primary btn-lg btn-block"/>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h5><b>{status}</b></h5> <ToastContainer />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> <
        /div> <
        Footer / >
        <
        />
    )
}