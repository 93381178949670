import React, {useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';


const NavbarMenu = () => {


  const [showDrawer, setShowDrawer] = useState(false);
  const handleToggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

return(
	<>
<Navbar bg="light" expand="lg" className="d-lg-none justify-content-end">
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggleDrawer} />
        <Navbar.Collapse id="basic-navbar-nav" className={showDrawer ? 'show' : ''}>
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/Shop">Shop</Nav.Link>
            <Nav.Link as={Link} to="/Review">Customer Reviews</Nav.Link>
            <Nav.Link as={Link} to="/About">About</Nav.Link>
            <Nav.Link as={Link} to="/Contact">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      </>
      )
  }

  export default NavbarMenu;