import React, {useState } from 'react';
import Header from './Header Component/Header';
import Footer from './Footer Component/Footer';
import { Link } from 'react-router-dom';
import  NavbarMenu  from './Reusable/NavbarMenu';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const images = [
  'images/poster1.jpg',
  'images/a12.jpg',
  'images/p2.png'

];
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,     
};

export default function Main() {
 
  return (
    <>
      <Header />
    
      <NavbarMenu />

<div className="slider-container">
       <div className="slider-wrapper">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} className="slider-image" alt={`Image ${index + 1}`} />
          </div>
        ))}
      </Slider>
      </div>
      </div>


          <div className="site-blocks-cover">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-2 col-md-8 order-md-2 col-sm-10 mx-auto text-center">
              <div className="site-block-cover-content">
                <img src="images/pragya1.jpeg" alt="Image" style={{ marginTop: '50px', height: 'auto', maxWidth: '100%', borderRadius: '25px' }} /><br/><br/>
                <Link to="/Shop" className="btn btn-black rounded-1">Shop Now</Link>
              </div>
            </div>
            <div className="col-lg-6 order-lg-1 col-md-8 order-md-1 col-sm-10 mx-auto text-center">
            <h3 style={{marginTop: '200px', fontfamily:'auto',color: 'black'}} >Shop Our Best Tshirts and Mugs Designs. For any Queries you can send me email on <b><u>praagnyacreations18@gmail.com</u></b></h3>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
