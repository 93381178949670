import React from 'react'
import Header from '../Header Component/Header';
import Footer from '../Footer Component/Footer';
import {Link} from 'react-router-dom';
import SideMenu from '../Reusable/SideMenu';
import  NavbarMenu  from '../Reusable/NavbarMenu';
import Tshirtlist from '../Reusable/Tshirtlist';
import Muglist from '../Reusable/Muglist';


export default function Shop(){
const colors = [{'color':'White'},{'color':'Black'},{'color':'Yellow'},{'color':'Orange'},{'color':'Royal Blue'}];
	return (
<>
<Header />
<NavbarMenu />
 <div className="custom-border-bottom py-3">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Shop</strong></div>
        </div>
      </div>
    </div>


    <div className="site-section">
      <div className="container">

        <div className="row mb-5">
          <div className="col-md-9 order-1">

            <div className="row align">
              <div className="col-md-12 mb-5">
                <div className="float-md-left"><h2 className="text-black h5">Shop All</h2></div>
                <div className="d-flex">     
                </div>
              </div>
            </div>
            
            <div className="row align">
              <div className="col-md-12 mb-5">
                <div className="float-md-left"><h2 className="text-black h5"><u>Tshirts</u></h2></div>
                <div className="d-flex">
                </div>
              </div>
            </div>  
            <Tshirtlist />

             <div className="row align">
              <div className="col-md-12 mb-5">
                <div className="float-md-left"><h2 className="text-black h5"><u>Mugs</u></h2></div>
                <div className="d-flex">
                </div>
              </div>
            </div>
            <Muglist /> 

                  <div className="row mb-5">
                 <div className="col-lg-6 col-md-6 item-entry mb-4">
                <Link to="/Contact" className="product-item md-height bg-gray d-block">
                  <img src="images/k1.jpeg" alt="Image" style={{top:5}}/>
                </Link>
                <h2 className="item-title"><Link to="#"><b>KeyChain</b></Link></h2>
                <strong className="item-price"> &#x20b9; 100</strong>
                <div className="star-rating">
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                </div>
              </div>
               <div className="col-lg-6 col-md-6 item-entry mb-4">
                <Link to="/Contact" className="product-item md-height bg-gray d-block">
                  <img src="images/cap1.png" alt="Image" style={{top:5}}/>
                </Link>
                <h2 className="item-title"><Link to="#"><b>Cap</b></Link></h2>
                <strong className="item-price"> &#x20b9; 450</strong>
                <div className="star-rating">
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                </div>
                <b style={{color: 'black'}}>Available in Universal Size for all Ages.</b><br/>
                <b style={{color: 'black'}}>Available Colors:</b>
                 <div style={{ display: 'flex', justifyContent: 'space-evenly',marginLeft:'-30px' }}>
                {
                  colors.map((i)=>{
                    return(
                    <>
            <b style={{color: 'black',fontFamily: 'fantasy'}}>{i.color}</b>
      
        </>
                    )
                  })
                }
                </div>  
              
</div>
              </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="site-block-27">
                  <ul>
                    <li><Link to="#">&lt;</Link></li>
                    <li className="active"><span>1</span></li>
                    {
                    // <li><Link to="#">2</Link></li>
                    // <li><Link to="#">3</Link></li>
                    // <li><Link to="#">4</Link></li>
                    // <li><Link to="#">5</Link></li>
                   
                }
                <li><Link to="#">&gt;</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
<SideMenu />
        </div>

      </div>
      </div>
   
 
<Footer />
</>
		)
}