import React from 'react';
import Header from '../Header Component/Header';
import Footer from '../Footer Component/Footer';
import {Link} from 'react-router-dom';
import  NavbarMenu  from '../Reusable/NavbarMenu';

export default function About(){
	return(
		<>
	<Header />
  <NavbarMenu />
<div className="container">
      <div className="row">
          <div className="col-md-3 order-1 align-self-end">
            <img src="images/a12.jpg" alt="Image" style={{width: '520px', height: '300px'}}/>
          </div>   
      </div>
  </div><br/><br/><br/>
  <div className="container">
  <h2><b style={{color:'black'}}> <u>Our Products</u></b></h2>
  <video src="images/v1.mp4" width="520" height="400" controls="controls" /></div>
  <div className="container"> <video src="images/v2_out.mp4" width="520" height="400" controls="controls"  /></div>
   <div className="container"> <video src="images/v3.mp4" width="520" height="400" controls="controls" /></div>
   <div className="container"> <video src="images/v5.mp4" width="520" height="400" controls="controls" /></div>
	<Footer />
		</>
		)
}