import React from 'react';
import Header from '../Header Component/Header';
import Footer from '../Footer Component/Footer';
import {Link} from 'react-router-dom';
import  NavbarMenu  from '../Reusable/NavbarMenu';

export default function Review(){

	  const reviews = [
    {
      id: 1,
      image: 'images/r1n.png',
      rating: 5,
      comment: "Great product! I'm very satisfied with my purchase.",
    },
    {
      id: 2,
      image: 'images/r2n.png',
      rating: 5,
      comment: "The quality of the product is outstanding. I'm impressed!",
    },
  ];


	return(
		<>
	<Header />
  <NavbarMenu />
  <div className = "site-section" >
        <div className="container">
        <div className="center">
          
            <h2 className="h3 mb-3 text-black" style={{textAlign: 'center'}}><u>Customer Reviews</u></h2>

{reviews.map((review) => (
        <div key={review.id} className="review">
          <img src={review.image} alt={`Customer ${review.id}`} />
          <div className="rating">
            {Array.from({ length: 5 }, (_, index) => (
              <span key={index} style={{color:'#ff9900'}}>&#9733;</span>
            )).slice(0, review.rating)}
            {Array.from({ length: 5 - review.rating }, (_, index) => (
              <span key={index + review.rating}>&#9734;</span>
            ))}
          </div>
          <div className="comment" style={{fontWeight: 'bold', color:'black'}}>{review.comment}</div>
        </div>
      ))}
</div></div></div>
<Footer />
</>
)
}