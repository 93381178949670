 import React from 'react';
 import {Link} from 'react-router-dom';

 export default function Header(){
  return (
  <div className="site-wrap">
    <div className="site-navbar bg-bg-red py-2 ">
      <div className="search-wrap">
        <div className="container">
        </div>
      </div>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
        <div className="logo">
              <div className="site-logo">
                <img src="images/logo2.png" alt="Your Logo" style={{ height: '150px' }} />
              </div>
            </div>
          <div className="logo">
            <div className="site-logo">
              <Link to="/" className="js-logo-clone"><b>Praagnya Creations</b></Link>
            </div>
          </div>

          <div className="main-nav d-none d-lg-block">
            <nav className="site-navigation text-right text-md-center" role="navigation">
              <ul className="site-menu js-clone-nav d-none d-lg-block">
                <li className="active">
                  <Link to="/"><b>Home</b></Link></li>
                <li className="has-children">
                  <Link to="/Shop"><b>Shop</b></Link>
                <ul className="dropdown">
                    <li><Link to="/Mugs"><b>Tea Mugs</b></Link></li>
                    <li>
                      <Link to="/Tshirts"><b>T-shirts</b></Link>
                    </li>
                     <li><Link to="/Designs">Mandala Designs</Link></li>
                  </ul>
                </li>
                <li><Link to="/Contact"><b>Contact</b></Link></li>
                  <li><Link to="/Review"><b>Reviews</b></Link></li>
                <li><Link to="/About"><b>About Us</b></Link></li>
              </ul>
            </nav>
          </div>
          <div className="icons">
           <Link to="https://www.instagram.com/praagnyacreations/" title="instagram icons" className="icons-btn d-inline-block" ><img src="images/i.png" style={{height:'30px'}} /></Link>&nbsp;&nbsp;&nbsp;
            <Link to="https://www.facebook.com/profile.php?id=100092625241824" title="facebook icons" className="icons-btn d-inline-block" ><img src="images/f.png" style={{height:'30px'}} /></Link>

          </div>
           
        </div>
      </div>
    </div>
   </div>


    )
 }




