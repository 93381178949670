import React from 'react';
import {Link} from 'react-router-dom';

export default function Muglist(){

   const mugs = [
  {
  	 name:'Black Patch Mug',
      src: 'images/t3.png',
      price: '450'
  },
  {
  	 name:'Black Patch Mug',
      src: 'images/nm5.png',
      price: '450'
  },
  {
  	 name:'Black Patch Mug',
      src: 'images/t2.png',
      price: '450'
  },
  {
  	 name:'White Mug Regular',
      src: 'images/nm4.png',
      price: '200'
  },
   {
   	 name:'White Mug Regular',
      src: 'images/nm1.png',
      price: '200'
  },
   {
   	 name:'Black Patch Mug',
      src: 'images/nm3.png',
      price: '450'
  },
   {
   	 name:'White Mug Regular',
      src: 'images/t1.png',
      price: '200'
  },
   {
   	 name:'White Mug Regular',
      src: 'images/nm6.png',
      price: '200'
  },
  {
  	 name:'White Mug Regular',
      src: 'images/nm7.png',
      price: '200'
  },
  {
     name:'Black Patch Mug',
      src: 'images/t5.png',
      price: '450'
  },

  ]
	return(
		<div className="row mb-5">
		<div className="col-lg-6 col-md-6 item-entry mb-4">
                <Link to="/Contact" className="product-item md-height bg-gray d-block">
                  <img src="images/Mug2.png" alt="Image" style={{top:8}} />
                </Link>
                <h2 className="item-title"><Link to="#"><b style={{color: 'black'}}>White Mug Regular </b></Link></h2>
                <strong className="item-price"> &#x20b9; 200</strong>
                <div className="star-rating">
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                </div>
              </div>       
		{
  mugs.map((u)=>{
    return(
     <div className="col-lg-6 col-md-6 item-entry mb-4">
                <Link to="/Contact" className="product-item md-height bg-gray d-block">
                  <img src={u.src} alt="Image"  style={{top:5}}/>
                </Link>
                <h2 className="item-title"><Link to="#"><b style={{color: 'black'}}>{u.name}</b></Link></h2>
                <strong className="item-price"> &#x20b9; {u.price}</strong>
                <div className="star-rating">
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                </div>
              </div>
    )
  })
}
</div>
		)
}