import React from 'react';
import {Link} from 'react-router-dom';

export default function Footer() {
	return (
		 <footer className="site-footer custom-border-top">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
            <h3 className="footer-heading mb-4">Promo</h3>
            <Link to="/Tshirts" className="block-6">
              
              <h3 className="font-weight-light  mb-0">Finding Your Perfect T-shirts This Summer</h3>
            </Link>
          </div>
          <div className="col-lg-5 ml-auto mb-5 mb-lg-0">
            <div className="row">
              <div className="col-md-12">
                <h3 className="footer-heading mb-4">Quick Links</h3>
              </div>
              <div className="col-md-6 col-lg-4">
                <ul className="list-unstyled">
                  <li><Link to="/Shop">Shop</Link></li>
                  <li><Link to="/Contact">Contact Us</Link></li>
                  <li><Link to="/About">About Us</Link></li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="col-md-6 col-lg-3">
            <div className="block-5 mb-5">
              <h3 className="footer-heading mb-4">Contact Info</h3>
              <ul className="list-unstyled">
               <li className="phone"><Link to="tel://+919408279437">+91 9408279437</Link></li>
                <li className="phone"><Link to="tel://+919426618910">+91 9426618910</Link></li>
                <li className="email">praagnyacreations18@gmail.com</li>
                 <li className="address">3/17, Gandhi Park, Nr.Sahajanand college, Nehru nagar, Ahmedabad, India</li>
              </ul>
            </div>

          </div>
        </div> 
        </div>
    </footer>

		)
}