import React from 'react';
import {Link} from 'react-router-dom';

export default function Tshirtlist(){
 const tshirts = [
  {
      name:'Mandala Print Yellow Tshirt',
      src: 'images/1.png',
      price: '650',
      color: 'yellow'
  },
  {
  	  name:'White Tshirt',
      src: 'images/2.png',
      price: '650',
      color: 'white'
  },
  {
      name:'Green Print/Navy Blue Collar Tshirt',
      src: 'images/3.png',
      price: '750',
      color: 'darkblue'
  },
  ]
  // this is because style is changed in this one.
  const tshirts2 =[
   {
      name:'Gray Printed Tshirt',
      src: 'images/4.png',
      price: '650',
      color: 'gray'
  },
   {
      name:'Printed Maroon Tshirt',
      src: 'images/5.png',
      price: '650',
      color: 'brown'
  },
  ]
    const renderSizeOptions = () => {
    const sizes = ['S', 'M', 'L', 'XL', 'XXL'];

    return sizes.map((size) => (
      <button style={{
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          margin: '5px',
          color: 'black',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          fontSize: '12px',
        }}
      >
        {size}
      </button>
    ));
  };

  return(
  	 <div className="row mb-5">
            {
                tshirts.map((i)=>{
                  return(
                      <div className="col-lg-6 col-md-6 item-entry mb-4">
                <Link to="/Contact" className="product-item md-height bg-gray d-block">
                  <img src={i.src} alt="Image" className="img-fluid" />
                </Link>
                <h2 className="item-title"><Link to="#"><b style={{color: 'black'}}>{i.name}</b></Link></h2>
                <strong className="item-price"> &#x20b9; {i.price}</strong>
                <div className="star-rating">
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                </div>
      {         
        //        <b style={{color: 'black'}}> Colors:</b><div style={{ display: 'flex', justifyContent: 'center' }}>
        //        <div
        //   key={i}
        //   style={{
        //     width: '25px',
        //     height: '25px',
        //     borderRadius: '50%',
        //     backgroundColor: i.color,
        //     margin: '-23px',
        //     marginLeft: '-282px',
        //   }}
        // ></div>

                 
        //        </div>
      }
               <b style={{color: 'black'}}>Available Sizes: </b><div className="size-options">{renderSizeOptions()}</div>
              </div>

                    )
                })}

                    {
                tshirts2.map((i)=>{
                  return(
                      <div className="col-lg-6 col-md-6 item-entry mb-4">
                <Link to="/Contact" className="product-item md-height bg-gray d-block">
                  <img src={i.src} alt="Image" style={{top: '-45px'}} />
                </Link>
                <h2 className="item-title"><Link to="#"><b style={{color: 'black'}}>{i.name}</b></Link></h2>
                <strong className="item-price"> &#x20b9; {i.price}</strong>
                <div className="star-rating">
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                  <span className="icon-star2 text-warning"></span>
                </div>
            {  
        //        <b style={{color: 'black'}}> Colors:</b>    <div style={{ display: 'flex', justifyContent: 'center' }}>
        //        <div
        //   key={i}
        //   style={{
        //     width: '25px',
        //     height: '25px',
        //     borderRadius: '50%',
        //     backgroundColor: i.color,
        //     margin: '-23px',
        //     marginLeft: '-282px',
        //   }}
        // ></div>

                 
        //        </div>
      }
                <b style={{color: 'black'}}>Available Sizes:</b> <div className="size-options">{renderSizeOptions()}</div>
              </div>

                    )
                })}
                 
              </div>
  	)
}